<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
  <v-card>
      <v-card-title>
        {{ itemState }}
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4">
        <p>Укажите дату {{ toAction }} свидетельства</p>
        <v-date-picker
          v-model="date"
          full-width
          :show-current="true"
          :first-day-of-week="1"
          :weekday-format="dayOfWeekToFormat"
        ></v-date-picker>
      </v-card-text>
      <v-divider />

      <v-card-text class="pt-4">
         <p class="font-weight-bold">
           {{ warningText }}
         </p>
      </v-card-text>

      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              outlined
              color="red"
              @click="dialog = false"
              :disabled="loading"
            >
               Отмена
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="runAction"
              :loading="loading"
              :disabled="!date"
            >
              {{ isIssue ? 'Прекратить' : 'Выдать'}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {dayOfWeekToFormat} from "@/services/JetDate";

const ISSUE_STATUS_GUID = '43c5797c-0ea8-4aea-8f2d-e89becd54e7b';
const NEW_STATUS_GUID = 'c2cd5446-7849-4ab4-89f8-bcef841fd2a0';

import GeneralServices from '@/services/GeneralServices';

export default {
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  name: 'IssueCertificate',
  data: () => ({
    selected: null,
    date: new Date().toISOString().substr(0, 10),
    stateId: null,
    loading: false,
    dialog: true,
    isIssue: null,
    toAction: null,
    fromAction: null,
    warningText: null,
    dayOfWeekToFormat: () => '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.selected = this.collection.master.selected;
    if (!this.selected) {
      this.show = false;
      jet.msg({
        text: 'Необходимо выбрать запись!',
        color: 'warning'
      });
      return;
    }

    this.stateId = this.selected.vccertificateregisterStateid;
    this.isIssue = this.stateId === ISSUE_STATUS_GUID ? true : false;
    this.toAction = this.isIssue ? 'прекращения' : 'выдачи';
    this.fromAction = this.isIssue ? 'выдачи' : 'прекращения';

    if (this.toAction === 'выдачи' && this.stateId !== NEW_STATUS_GUID) {
      this.dialog = false;
      jet.msg({
        text: `Действие ${this.toAction} свидетельства доступно для статуса только "Новый"!`,
        color: 'warning'
      });
      return;
    }
  },
  computed: {
    itemState() {
      return this.isIssue ? 'Прекратить действие свидетельства' : 'Выдать свидетельство';
    },
  },
  methods: {
    async closeCertificate(certificateId, servicedRouteId){
      const optionsQuery = {
        type: 'query',
        query: '9fb80c79-2908-4594-8c36-79b9d9e8c442.issueOrCloseCertificate',
        params: {
          certificateId: certificateId,
          servicedRouteId: servicedRouteId,
          tenantId: this.selected._sec_attrsTenantid,
          userId: this.selected._sec_attrsUserid,
          indate: this.date,
          action: 1,
        }
      };
      try {
        const resp = await jet.http.post(optionsQuery);
        this.show = false;
        if (resp.error) {
          throw resp.error;
        }

        jet.msg({
          text: `Действие ${this.itemState} для карты ${this.selected.vccertificateregisterCardnumber}
           ${this.selected.vccertificateregisterCardseries} выполнено успешно!`
        });
        this.collection.refresh(certificateId);
      } catch (e) {
        jet.msg({
          text: 'Не удалось прекратить действие свидетельства',
          color: 'warning'
        });
        console.error('IssueCertificate: closeCertificate()', e);
        return;
      }
    },
    async issueCertificate(certificateId, servicedRouteId){
      const optionsQuery = {
        type: 'query',
        query: '9fb80c79-2908-4594-8c36-79b9d9e8c442.issueOrCloseCertificate',
        params: {
          certificateId: certificateId,
          servicedRouteId: servicedRouteId,
          tenantId: this.selected._sec_attrsTenantid,
          userId: this.selected._sec_attrsUserid,
          indate: this.date,
          action: 0,
        }
      };
      try {
        const resp = await jet.http.post(optionsQuery);
        this.show = false;
        if (resp.error) {
          throw resp.error;
        }

        jet.msg({
          text: `Действие ${this.itemState} для карты ${this.selected.vccertificateregisterCardnumber}
           ${this.selected.vccertificateregisterCardseries} выполнено успешно!`
        });
        this.collection.refresh(certificateId);
      } catch (e) {
        jet.msg({
          text: 'Не удалось выполнить действие по выдаче свидетельства',
          color: 'warning'
        });
        console.error('IssueCertificate: issueCertificate()', e);
        return;
      }
    },
    async runAction(){
      const certificateId = this.selected.vccertificateregisterId;
      const servicedRouteId = this.selected.vccertificateregisterServicedrouteid;

      let optionsQuery = {
        type: 'query',
        query: '9fb80c79-2908-4594-8c36-79b9d9e8c442.checkUnique',
        params: {
          cardRegisterID: certificateId,
          servicedRouteID: servicedRouteId,
          stateID: this.stateId,
        }
      };
      try {
        const resp = await jet.http.post(optionsQuery);
        if (resp.error) {
          throw resp.error;
        }
        if (resp.result.data.length > 0) {
          this.warningText = `Для выбранного обслуживаемого маршрута
            уже добавлена карта
            ${resp.result.data[0][2]}
            со статусом «Выдано»,
            после сохранения существующая запись будет переведена
            в статус «Утратил силу»`;
        }
      } catch (e) {
        jet.msg({
          text: 'Не удалось получить список карт маршрута',
          color: 'warning'
        });
        console.error('IssueCertificate: runAction()', e);
        return;
      }

      if(this.isIssue){
        await this.closeCertificate(certificateId, servicedRouteId);
      } else {
        await this.issueCertificate(certificateId, servicedRouteId)
      }
      this.dialog = false;
    },
  }
};
</script>
